import styled from "styled-components";

import { mono, color, typography } from "./theme";

export const H1 = styled.h1`
  font-size: 26px;
  line-height: 1.23;
  text-align: center;
  font-weight: ${typography.weight[500]};
  color: ${mono[111]};
  text-transform: uppercase;
`;

export const H2 = styled.h2`
  font-size: 18px;
  letter-spacing: -0.8px;
  text-align: center;
  font-weight: ${typography.weight[600]};
  color: ${mono[111]};
`;

export const H3 = styled.h3`
  font-size: 14px;
  line-height: normal;
  letter-spacing: -0.5px;
  font-weight: ${typography.weight[500]};
  color: ${mono[111]};
`;

export const H4 = styled.h4`
  font-size: 14px;
  line-height: normal;
  letter-spacing: -0.2px;
  font-weight: ${typography.weight[700]};
  color: ${mono[111]};
`;

export const H5 = styled.h4`
  font-size: 12px;
  line-height: normal;
  letter-spacing: -0.2px;
  font-weight: ${typography.weight[700]};
  color: ${mono[111]};
`;

export const Body = styled.p<{ $subtle: boolean; $small: boolean }>`
  font-size: 14px;
  line-height: 1.57;
  letter-spacing: -0.5px;
  font-weight: ${typography.weight[500]};
  color: ${mono[111]};
`;

export const LabelText = styled.span`
  display: inline-block;
  width: 100%;
  line-height: 1.83;
  letter-spacing: -0.2px;

  font-size: ${typography.size[12]};
  font-weight: ${typography.weight[600]};
  color: ${mono[666]};
`;

export const ErrorText = styled.span.attrs((props) => ({
  role: "alert",
  className: `${props.className || ""} form-error`,
}))`
  display: block;
  line-height: 22px;
  font-weight: ${typography.weight[300]};
  font-size: ${typography.size[12]};
  color: ${color.negative};
`;

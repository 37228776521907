import React, { FC } from "react";
import { DefaultTheme, ThemeProvider } from "styled-components";

import GlobalStyles from "./globalTheme";

const Providers: FC = ({ children }) => (
  <React.StrictMode>
    <ThemeProvider theme={{} as DefaultTheme}>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  </React.StrictMode>
);

export default Providers;

import React, { FC } from "react";
import styled from "styled-components";

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgb(57, 46, 90);
  min-height: 100vh;
  padding: 1rem;

  @media (min-width: 600px) {
    padding: 100px 1rem;
  }
`;
const LayoutUnauthorised: FC = ({ children }) => {
  return <AppWrapper>{children}</AppWrapper>;
};

export default LayoutUnauthorised;

import styled from "styled-components";

import { Card } from "../../components/Containers/Card";
import { H2 } from "../../theme/typography";
import { ReactComponent as Logo } from "../../assets/svgs/logo.svg";
import { Link } from "../../components/Button/button.styles";
import { color } from "../../theme/theme";

const RedirectCard = styled(Card)`
  max-width: 420px;
`;
const Heading = styled(H2)`
  margin: 1rem 0 0;
`;
const P = styled.p`
  text-align: center;
  margin: 1rem 0 0;
  line-height: 1.5;
`;
const LinkButton = styled(Link)`
  margin-top: 2rem;
`;

export const LogoWrapper = styled.div`
  text-align: center;
  max-width: 206px;
  height: 64px;
  margin: 0 auto 2.5rem auto;
  color: ${color.primary};
  svg {
    height: 100%;
  }
`;

type ErrorProps = {
  title: string;
  text: string;
};
const ErrorFallback = ({ title, text }: ErrorProps) => {
  return (
    <RedirectCard>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <Heading as="h1">{title}</Heading>
      <P>{text}</P>
      <LinkButton href="https://standbyhealthcare.com">
        Go to Standby Healthcare
      </LinkButton>
    </RedirectCard>
  );
};

export default ErrorFallback;

export const color = {
  primary: "#C64B94",
  // older primary - #CE0F69
  // oldest primary - #ca599e
  // Status
  positive: "#66BF3C",
  negative: "#f51919",
  warning: "#E69D00",
};

export const mono = {
  fff: "#fff",
  f8: "#F8F8F8",
  f3: "#F3F3F3",
  eee: "#eee",
  ddd: "#ddd",
  bdbdbd: "#BDBDBD",
  808080: "#808080",
  777: "#777",
  666: "#666",
  333: "#333",
  111: "#111",
};

export const typography = {
  type: {
    primary: '"Nunito Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
  },
  weight: {
    300: 300,
    400: 400,
    500: 500,
    600: 600,
    700: 700,
  },
  size: {
    12: "12px",
    14: "14px",
    16: "16px",
    20: "20px",
    24: "24px",
    32: "32px",
    40: "40px",
    48: "48px",
  },
};
export const spacing = {
  4: "4px",
  8: "8px",
  16: "16px",
  24: "24px",
  32: "32px",
  40: "40px",
  64: "64px",
  128: "128px",
};

export const border = {
  radius: {
    3: "3px",
    6: "6px",
    10: "10px",
    24: "24px",
  },
  shadow: {
    light: "1px 1px 4px 0 #d8dadb",
    dark: "1px 1px 4px 0 rgba(10, 10, 10, 0.7)",
  },
};

export const layout = {
  breakpoints: {
    small: "600px",
    medium: "800px",
    large: "1000px",
    xlarge: "1200px",
  },
  nav: {
    mobileHeight: "70px",
    desktopWidth: "240px",
  },
};
